import {
  LOAD_ENVIRONMENT_OK
} from '../actions/session';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  theme: 'default',
  searches: [],
  collections: [],
}

const sessionReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      console.log('SESSION RED', {state, rest});

      return {...state, ...rest }

      case LOAD_ENVIRONMENT_OK:
        console.log('LOAD ENV REDUCER', {state, rest});
        return {
          ...state,
          searches: rest.searches,
          collections: rest.collections,
        };
    default:
      return state
  }
}

export default sessionReducer; 
