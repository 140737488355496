import {
	FETCH_CAMPAIGNS_START,
	FETCH_CAMPAIGNS_OK,
	FETCH_CAMPAIGNS_ERR,
	FETCH_COLLECTIONS_START,
	FETCH_COLLECTIONS_OK,
	FETCH_COLLECTIONS_ERR,
	FETCH_USERS_START,
	FETCH_USERS_OK,
	FETCH_USERS_ERR,
	FETCH_TAGS_START,
	FETCH_TAGS_OK,
	FETCH_TAGS_ERR,
	ADD_GLOBAL_TAG_START,
} from '../actions/options';
import {
	LOAD_ENVIRONMENT_OK
} from '../actions/session';
 import {
	CREATE_COLLECTION_ACTION_OK,
 } from '../actions/collections';
 import {
	CREATE_USER_ACTION_OK,
 } from '../actions/users';

import {
	expandDynamoKeys
} from '../shared/constants';

const defaultOptionsState = {
	isLoading: false,
	errorMsg: '',
	Campaigns: [],
	Collections: [],
	Users: [],
	UsStates: [],
	Tags: [],
	AgeGroups: [
		{ id: '18-34', label: '18-34' },
		{ id: '35-44', label: '35-44' },
		{ id: '45-54', label: '45-54' },
		{ id: '55-64', label: '55-64' },
		{ id: '65+', label: '65+' },
	],
	Experience: [
		{ id: 'Less than 1', label: 'Less than 1' },
		{ id: '1-2', label: '1-2' },
		{ id: '2-3', label: '2-3' },
		{ id: 'Over 3', label: 'Over 3' },
	],
	Ratings: [
		{ id: 0, label: 'Unrated' },
		{ id: 1, label: '1 Star' },
		{ id: 2, label: '2 Stars' },
		{ id: 3, label: '3 Stars' },
	],
	Genders: [
		{ id: 'Male', label: 'Male' },
		{ id: 'Female', label: 'Female' },
		{ id: 'Other', label: 'Other' },
	],
	UserRoles: [
		{ id: 'Admin', label: 'Admin' },
		{ id: 'User', label: 'User' },
		{ id: 'Partner', label: 'Partner' },
	],
	Statuses: [
		{ id: 'New', label: 'In Process' },
		{ id: 'Active', label: 'Active' },
		{ id: 'Delete', label: 'Deleted' },
	],
};

export default function optionsReducer(state = defaultOptionsState, action) {

	switch (action.type) {

		case ADD_GLOBAL_TAG_START:
			return {
				...state,
				Tags: [ ...state.Tags, action.tag].sort(),
			};

		case FETCH_CAMPAIGNS_START:
			return {
				...state,
				isLoading: true,
				errorMsg: '',
			
			};

		case CREATE_COLLECTION_ACTION_OK:
			console.log('OPTIONS REDUCER: Create Collection', {state, action});
			return {
				...state,
				Collections: [ action.collection, ...state.Collections ]
			};

		case FETCH_CAMPAIGNS_OK:
			console.log('FETCH CAMPAIGNS', {state, action});
			return {
				...state,
				Campaigns: action.campaigns,
			};

		case FETCH_CAMPAIGNS_ERR:
			return {
				...state,
				isLoading: false,
				errorMsg: action.error
			};

		case FETCH_COLLECTIONS_START:
			return {
				...state,
				isLoading: true,
				errorMsg: '',
			
			};

		case FETCH_COLLECTIONS_OK:
			console.log('FETCH COLLECTIONS', {state, action});
			return {
				...state,
				Collections: action.collections,
			};

		case FETCH_COLLECTIONS_ERR:
			return {
				...state,
				isLoading: false,
				errorMsg: action.error
			};

		case FETCH_USERS_START:
			return {
				...state,
				isLoading: true,
				errorMsg: '',
			
			};

		case FETCH_USERS_OK:
			console.log('FETCH USERS', {state, action});
			return {
				...state,
				Users: action.users,
			};

		case FETCH_USERS_ERR:
			return {
				...state,
				isLoading: false,
				errorMsg: action.error
			};

		case CREATE_USER_ACTION_OK:
			console.log('CREATE USER', {state, action});
			return {
				...state,
				Users: [
					action.user,
					...state.Users
				]
			};

		case LOAD_ENVIRONMENT_OK:
			console.log('LOAD ENV', {state, action});
			return {
				...state,
				Tags: action.tags,
			};

		case FETCH_TAGS_OK:
			console.log('FETCH TAGS', {state, action});
			sessionStorage.setItem('tags', JSON.stringify(action.tags));
			return {
				...state,
				Tags: action.tags
			};

		default:
			return state;
	}
}
