import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';

const dayjs = require('dayjs');

export const FETCH_COLLECTION_ACTION_START = 'FETCH_COLLECTION_ACTION_START';
export const FETCH_COLLECTION_ACTION_OK = 'FETCH_COLLECTION_ACTION_OK';
export const FETCH_COLLECTION_ACTION_ERR = 'FETCH_COLLECTION_ACTION_ERR';

const fetchCollectionStart = (collectionid) => { return { type: FETCH_COLLECTION_ACTION_START, collectionid}};
const fetchCollectionOk = (collection) => { return { type: FETCH_COLLECTION_ACTION_OK, collection }};
const fetchCollectionErr = (error) => { return { type: FETCH_COLLECTION_ACTION_ERR, error }};

export function fetchCollectionAction(collectionid, successCb, errorCb) {

	console.log('fetchCollectionAction: START', collectionid);

	return async (dispatch) => {

		dispatch(fetchCollectionStart(collectionid));

		try {
			let endpoint = '/api/admin/collection/' + collectionid;
			console.log('FETCH Fetching ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('FETCH Found collection', { col: payload.collection });
			dispatch(fetchCollectionOk(payload.collection));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('FETCH collection error:', { error });
			dispatch(fetchCollectionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}


export const CREATE_COLLECTION_ACTION_START = 'CREATE_COLLECTION_ACTION_START';
export const CREATE_COLLECTION_ACTION_OK = 'CREATE_COLLECTION_ACTION_OK';
export const CREATE_COLLECTION_ACTION_ERR = 'CREATE_COLLECTION_ACTION_ERR';

const createCollectionStart = () => { return { type: CREATE_COLLECTION_ACTION_START}};
const createCollectionOk = (collection) => { return { type: CREATE_COLLECTION_ACTION_OK, collection }};
const createCollectionErr = (error) => { return { type: CREATE_COLLECTION_ACTION_ERR, error }};

export function createCollectionAction(collection, successCb, errorCb) {

	console.log('createCollectionsAction START');

	return async (dispatch) => {

		console.log('createCollectionAction: START', collection);
	
		dispatch(createCollectionStart());

		try {
			let endpoint = '/api/admin/collection';
			console.log('POST Collection ... ' + endpoint, collection);
				
			const newRec = await API.post('AncestryVmpApi', endpoint, { body: { ...collection, entityType: 'Collection', entityStatus: 'Active' }});
			console.log('Put success');
			dispatch(createCollectionOk(newRec.collection));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('Create collection error:', { error });
			dispatch(createCollectionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const SAVE_COLLECTION_ACTION_START = 'SAVE_COLLECTION_ACTION_START';
export const SAVE_COLLECTION_ACTION_OK = 'SAVE_COLLECTION_ACTION_OK';
export const SAVE_COLLECTION_ACTION_ERR = 'SAVE_COLLECTION_ACTION_ERR';

const saveCollectionStart = () => { return { type: SAVE_COLLECTION_ACTION_START}};
const saveCollectionOk = (collection) => { return { type: SAVE_COLLECTION_ACTION_OK, collection }};
const saveCollectionErr = (error) => { return { type: SAVE_COLLECTION_ACTION_ERR, error }};

export function saveCollectionAction(collectionid, values, successCb, errorCb) {

	console.log('saveCollectionAction START');

	return async (dispatch) => {

		console.log('saveCollectionAction: START', {collectionid, values});
	
		dispatch(saveCollectionStart());

		try {
			let endpoint = '/api/admin/collection/' + collectionid;
			console.log('POST Collection ... ' + endpoint, values);
				
			const newRec = await API.post('AncestryVmpApi', endpoint, { body: values });
			console.log('Save collection success');
			dispatch(saveCollectionOk(newRec.collection));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('Save collection error:', { error });
			dispatch(saveCollectionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const COLLECTION_ADD_MEMBER_ACTION_START = 'COLLECTION_ADD_MEMBER_ACTION_START';
export const COLLECTION_ADD_MEMBER_ACTION_OK = 'COLLECTION_ADD_MEMBER_ACTION_OK';
export const COLLECTION_ADD_MEMBER_ACTION_ERR = 'COLLECTION_ADD_MEMBER_ACTION_ERR';

const collectionAddMemberStart = () => { return { type: COLLECTION_ADD_MEMBER_ACTION_START}};
const collectionAddMemberOk = (memberid) => { return { type: COLLECTION_ADD_MEMBER_ACTION_OK, memberid}};
const collectionAddMemberErr = (error) => { return { type: COLLECTION_ADD_MEMBER_ACTION_ERR, error }};

export function collectionAddMemberAction(collectionid, memberid, successCb, errorCb) {

	console.log('collectionAddMembersAction START');

	return async (dispatch) => {

		console.log('collectionAddMemberAction: START', collectionid);
	
		dispatch(collectionAddMemberStart());

		try {
			let endpoint = '/api/admin/collection/' + collectionid + '/member';
			console.log('POST Collection ... ' + endpoint, collectionid);
				
			const newRec = await API.post('AncestryVmpApi', endpoint, { body: { memberid, action: 'Add' }});
			console.log('Collection add member success');
			dispatch(collectionAddMemberOk(memberid));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('collection add member error:', { error });
			dispatch(collectionAddMemberErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const COLLECTION_REMOVE_MEMBER_ACTION_START = 'COLLECTION_REMOVE_MEMBER_ACTION_START';
export const COLLECTION_REMOVE_MEMBER_ACTION_OK = 'COLLECTION_REMOVE_MEMBER_ACTION_OK';
export const COLLECTION_REMOVE_MEMBER_ACTION_ERR = 'COLLECTION_REMOVE_MEMBER_ACTION_ERR';

const collectionRemoveMemberStart = () => { return { type: COLLECTION_REMOVE_MEMBER_ACTION_START}};
const collectionRemoveMemberOk = (memberid, collectionid) => { return { type: COLLECTION_REMOVE_MEMBER_ACTION_OK, memberid, collectionid}};
const collectionRemoveMemberErr = (error) => { return { type: COLLECTION_REMOVE_MEMBER_ACTION_ERR, error }};

export function collectionRemoveMemberAction(collectionid, memberid, successCb, errorCb) {

	console.log('collectionRemoveMembersAction START');

	return async (dispatch) => {

		console.log('collectionRemoveMemberAction: START', collectionid);
	
		dispatch(collectionRemoveMemberStart());

		try {
			let endpoint = '/api/admin/collection/' + collectionid + '/member';
			console.log('POST Collection ... ' + endpoint, collectionid);
				
			const newRec = await API.post('AncestryVmpApi', endpoint, { body: { memberid, action: 'Remove' }});
			console.log('Collection remove member success');
			dispatch(collectionRemoveMemberOk(memberid, collectionid));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('collection remove member error:', { error });
			dispatch(collectionRemoveMemberErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const COLLECTION_ADD_SUBMISSION_ACTION_START = 'COLLECTION_ADD_SUBMISSION_ACTION_START';
export const COLLECTION_ADD_SUBMISSION_ACTION_OK = 'COLLECTION_ADD_SUBMISSION_ACTION_OK';
export const COLLECTION_ADD_SUBMISSION_ACTION_ERR = 'COLLECTION_ADD_SUBMISSION_ACTION_ERR';

const collectionAddSubmissionStart = () => { return { type: COLLECTION_ADD_SUBMISSION_ACTION_START}};
const collectionAddSubmissionOk = (memberid) => { return { type: COLLECTION_ADD_SUBMISSION_ACTION_OK, memberid}};
const collectionAddSubmissionErr = (error) => { return { type: COLLECTION_ADD_SUBMISSION_ACTION_ERR, error }};

export function collectionAddSubmissionAction(collectionid, submissionid, successCb, errorCb) {

	console.log('collectionAddSubmissionsAction START');

	return async (dispatch) => {

		console.log('collectionAddSubmissionAction: START', collectionid);
	
		dispatch(collectionAddSubmissionStart());

		try {
			let endpoint = '/api/admin/collection/' + collectionid + '/submission';
			console.log('POST Collection ... ' + endpoint, collectionid);
				
			await API.post('AncestryVmpApi', endpoint, { body: { submissionid, action: 'Add' }});
			console.log('Collection add member success');
			dispatch(collectionAddSubmissionOk(submissionid));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('collection add member error:', { error });
			dispatch(collectionAddSubmissionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const COLLECTION_REMOVE_SUBMISSION_ACTION_START = 'COLLECTION_REMOVE_SUBMISSION_ACTION_START';
export const COLLECTION_REMOVE_SUBMISSION_ACTION_OK = 'COLLECTION_REMOVE_SUBMISSION_ACTION_OK';
export const COLLECTION_REMOVE_SUBMISSION_ACTION_ERR = 'COLLECTION_REMOVE_SUBMISSION_ACTION_ERR';

const collectionRemoveSubmissionStart = () => { return { type: COLLECTION_REMOVE_SUBMISSION_ACTION_START}};
const collectionRemoveSubmissionOk = (submissionid, collectionid) => { return { type: COLLECTION_REMOVE_SUBMISSION_ACTION_OK, submissionid, collectionid}};
const collectionRemoveSubmissionErr = (error) => { return { type: COLLECTION_REMOVE_SUBMISSION_ACTION_ERR, error }};

export function collectionRemoveSubmissionAction(collectionid, submissionid, successCb, errorCb) {

	console.log('collectionRemoveSubmissionsAction START');

	return async (dispatch) => {

		console.log('collectionRemoveSubmissionAction: START', collectionid);
	
		dispatch(collectionRemoveSubmissionStart());

		try {
			let endpoint = '/api/admin/collection/' + collectionid + '/submission';
			console.log('POST Collection ... ' + endpoint, collectionid);
				
			const payload = await API.post('AncestryVmpApi', endpoint, { body: { submissionid, action: 'Remove' }});
			console.log('Collection remove member success', payload);
			dispatch(collectionRemoveSubmissionOk(payload.submissionid, payload.collectionid)); // returns fully qualified id
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('collection remove member error:', { error });
			dispatch(collectionRemoveSubmissionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}
	}
}
