import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';

const dayjs = require('dayjs');

export const FETCH_CAMPAIGNS_START = 'FETCH_CAMPAIGNS_START';
export const FETCH_CAMPAIGNS_OK = 'FETCH_CAMPAIGNS_OK';
export const FETCH_CAMPAIGNS_ERR = 'FETCH_CAMPAIGNS_ERR';

const fetchCampaignsStart = () => { return { type: FETCH_CAMPAIGNS_START }};
const fetchCampaignsOk = (campaigns) => { return { type: FETCH_CAMPAIGNS_OK, campaigns }};
const fetchCampaignsErr = (error) => { return { type: FETCH_CAMPAIGNS_ERR, error}};

export function fetchCampaignsAction(successCb, errorCb) {

	console.log('fetchCampaignsAction START');

	return async (dispatch) => {

		dispatch(fetchCampaignsStart());
		try {
			let endpoint = '/api/admin/options/Campaign';
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found campaigns' , { payload });
			dispatch(fetchCampaignsOk(payload.Campaign));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY campaigns error:', { error });
			errorCb && errorCb(error.message);
			dispatch(fetchCampaignsErr(error.message));
			return false;
		}

	}
}

export const FETCH_COLLECTIONS_START = 'FETCH_COLLECTIONS_START';
export const FETCH_COLLECTIONS_OK = 'FETCH_COLLECTIONS_OK';
export const FETCH_COLLECTIONS_ERR = 'FETCH_COLLECTIONS_ERR';

const fetchCollectionsStart = () => { return { type: FETCH_COLLECTIONS_START }};
const fetchCollectionsOk = (collections) => { return { type: FETCH_COLLECTIONS_OK, collections }};
const fetchCollectionsErr = (error) => { return { type: FETCH_COLLECTIONS_ERR, error}};

export function fetchCollectionsAction(successCb, errorCb) {

	console.log('fetchCollectionsAction START');

	return async (dispatch) => {

		dispatch(fetchCollectionsStart());
		try {
			let endpoint = '/api/admin/options/Collection';
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found collections' , { payload });
			dispatch(fetchCollectionsOk(payload.Collection));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY collections error:', { error });
			errorCb && errorCb(error.message);
			dispatch(fetchCollectionsErr(error.message));
			return false;
		}

	}
}

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_OK = 'FETCH_USERS_OK';
export const FETCH_USERS_ERR = 'FETCH_USERS_ERR';

const fetchUsersStart = () => { return { type: FETCH_USERS_START }};
const fetchUsersOk = (users) => { return { type: FETCH_USERS_OK, users }};
const fetchUsersErr = (error) => { return { type: FETCH_USERS_ERR, error}};

export function fetchUsersAction(successCb, errorCb) {

	console.log('fetchUsersAction START');

	return async (dispatch) => {

		dispatch(fetchUsersStart());
		try {
			let endpoint = '/api/admin/options/User';
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found users' , { payload });
			dispatch(fetchUsersOk(payload.User));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY users error:', { error });
			errorCb && errorCb(error.message);
			dispatch(fetchUsersErr(error.message));
			return false;
		}

	}
}

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_OK = 'FETCH_TAGS_OK';
export const FETCH_TAGS_ERR = 'FETCH_TAGS_ERR';

const fetchTagsStart = () => { return { type: FETCH_TAGS_START }};
const fetchTagsOk = (tags) => { return { type: FETCH_TAGS_OK, tags}};
const fetchTagsErr = (error) => { return { type: FETCH_TAGS_ERR, error}};

export function fetchTagsAction(successCb, errorCb) {

	console.log('fetchTagsAction START');

	return async (dispatch) => {

		dispatch(fetchTagsStart());
		try {
			let endpoint = '/api/admin/tags';
			console.log('QUERY Grabbing global tags list ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found tags' , { payload });
			dispatch(fetchTagsOk(payload.tags));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY tags error:', { error });
			errorCb && errorCb(error.message);
			dispatch(fetchTagsErr(error.message));
			return false;
		}

	}
}

export const ADD_GLOBAL_TAG_START = 'ADD_GLOBAL_TAG_START';
export const ADD_GLOBAL_TAG_OK = 'ADD_GLOBAL_TAG_OK';
export const ADD_GLOBAL_TAG_ERR = 'ADD_GLOBAL_TAG_ERR';

const addGlobalTagStart = (tag) => { return { type: ADD_GLOBAL_TAG_START, tag }};
const addGlobalTagOk = () => { return { type: ADD_GLOBAL_TAG_OK }};
const addGlobalTagErr = (error) => { return { type: ADD_GLOBAL_TAG_ERR, error}};

export function addGlobalTagAction(tag, successCb, errorCb) {

	console.log('addGlobalTagAction START');

	return async (dispatch) => {

		dispatch(addGlobalTagStart(tag));
		try {
			let endpoint = `/api/admin/tags/${tag}`;
			console.log(`QUERY Adding global tag ${tag} ... ${endpoint}`);
				
			const payload = await API.post('AncestryVmpApi', endpoint);
			console.log('QUERY Finished adding tag' , { payload });
			dispatch(addGlobalTagOk());
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY tags error:', { error });
			errorCb && errorCb(error.message);
			dispatch(addGlobalTagErr(error.message));
			return false;
		}

	}
}