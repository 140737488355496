import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';

const dayjs = require('dayjs');

export const SEARCH_SUBMISSIONS_ACTION_START = 'SEARCH_SUBMISSIONS_ACTION_START';
export const SEARCH_SUBMISSIONS_ACTION_OK = 'SEARCH_SUBMISSIONS_ACTION_OK';
export const SEARCH_SUBMISSIONS_ACTION_ERR = 'SEARCH_SUBMISSIONS_ACTION_ERR';

const searchSubmissionsStart = (criteria) => { return { type: SEARCH_SUBMISSIONS_ACTION_START, criteria }};
const searchSubmissionsOk = (results, total, criteria) => { 
	return { type: SEARCH_SUBMISSIONS_ACTION_OK, results, total, criteria}
};
const searchSubmissionsErr = (error) => { return { type: SEARCH_SUBMISSIONS_ACTION_ERR, error }};

export function searchSubmissionsAction(searchCriteria, successCb, errorCb) {

	console.log('searchSubmissionsAction START');

	return async (dispatch) => {

		const decoded = decodeCriteria((searchCriteria === undefined) ? '' : searchCriteria);
		console.log('searchSubmissionsAction: START', { searchCriteria, decoded });
	
		dispatch(searchSubmissionsStart(searchCriteria));

		try {
			let endpoint = '/api/admin/submissions/' + searchCriteria;
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found submissions', { payload });
			dispatch(searchSubmissionsOk(payload.results, payload.total, payload.criteria));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY submission error:', { error });
			dispatch(searchSubmissionsErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const FETCH_SUBMISSION_ACTION_START = 'FETCH_SUBMISSION_ACTION_START';
export const FETCH_SUBMISSION_ACTION_OK = 'FETCH_SUBMISSION_ACTION_OK';
export const FETCH_SUBMISSION_ACTION_ERR = 'FETCH_SUBMISSION_ACTION_ERR';

const fetchSubmissionStart = () => { return { type: FETCH_SUBMISSION_ACTION_START}};
const fetchSubmissionOk = (submission) => { return { type: FETCH_SUBMISSION_ACTION_OK, submission }};
const fetchSubmissionErr = (error) => { return { type: FETCH_SUBMISSION_ACTION_ERR, error }};

export function fetchSubmissionAction(submissionid, successCb, errorCb) {

	console.log('fetchSubmissionsAction START');

	return async (dispatch) => {

		dispatch(fetchSubmissionStart());

		try {
			let endpoint = '/api/admin/submission/' + submissionid;
			console.log('FETCH ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found submission', { payload });
			dispatch(fetchSubmissionOk(payload.submission));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('FETCH submission error:', { error });
			dispatch(fetchSubmissionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const ADD_SUBMISSION_TO_COLLECTION_ACTION_START = 'ADD_SUBMISSION_TO_COLLECTION_ACTION_START';
export const ADD_SUBMISSION_TO_COLLECTION_ACTION_OK = 'ADD_SUBMISSION_TO_COLLECTION_ACTION_OK';
export const ADD_SUBMISSION_TO_COLLECTION_ACTION_ERR = 'ADD_SUBMISSION_TO_COLLECTION_ACTION_ERR';

const addSubmissionToCollectionStart = () => { return { type: ADD_SUBMISSION_TO_COLLECTION_ACTION_START}};
const addSubmissionToCollectionOk = (collectionid, submissionid) => { return { type: ADD_SUBMISSION_TO_COLLECTION_ACTION_OK, collectionid, submissionid }};
const addSubmissionToCollectionErr = (error) => { return { type: ADD_SUBMISSION_TO_COLLECTION_ACTION_ERR, error }};

export function addSubmissionToCollectionAction(submissionid, collectionid, successCb, errorCb) {

	console.log('addSubmissionToCollectionsAction START', {submissionid, collectionid});

	return async (dispatch) => {

		dispatch(addSubmissionToCollectionStart());

		try {
			let endpoint = `/api/admin/collection/${collectionid}/submission`;
			console.log('ADD SUB TO COLL ... ' + endpoint);
				
			const payload = await API.post('AncestryVmpApi', endpoint, {body: { action: 'Add', submissionid }});
			console.log('POST sub to coll ', { payload });
			dispatch(addSubmissionToCollectionOk(payload.collectionid, payload.submissionid));
			console.log('POST sub to coll POST DISPATCH');
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST submission to collection error:', { error });
			dispatch(addSubmissionToCollectionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}


export const REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_START = 'REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_START';
export const REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK = 'REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK';
export const REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_ERR = 'REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_ERR';

const removeSubmissionFromCollectionStart = () => { return { type: REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_START}};
const removeSubmissionFromCollectionOk = (collectionid, submissionid) => { return { type: REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK, collectionid, submissionid }};
const removeSubmissionFromCollectionErr = (error) => { return { type: REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_ERR, error }};

export function removeSubmissionFromCollectionAction(submissionid, collectionid, successCb, errorCb) {

	console.log('removeSubmissionFromCollectionsAction START', {submissionid, collectionid});

	return async (dispatch) => {

		dispatch(removeSubmissionFromCollectionStart());

		try {
			let endpoint = `/api/admin/collection/${collectionid}/submission`;
			console.log('REMOVE SUB TO COLL ... ' + endpoint);
				
			const payload = await API.post('AncestryVmpApi', endpoint, {body: { action: 'Add', submissionid }});
			console.log('POST remove sub from coll ', { payload });
			dispatch(removeSubmissionFromCollectionOk(payload.collectionid, payload.submissionid));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST submission from collection error:', { err: JSON.stringify(error, true, 4) });
			dispatch(removeSubmissionFromCollectionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const ADD_SUBMISSION_NOTE_ACTION_START = 'ADD_SUBMISSION_NOTE_ACTION_START';
export const ADD_SUBMISSION_NOTE_ACTION_OK = 'ADD_SUBMISSION_NOTE_ACTION_OK';
export const ADD_SUBMISSION_NOTE_ACTION_ERR = 'ADD_SUBMISSION_NOTE_ACTION_ERR';

const addSubmissionNoteStart = () => { return { type: ADD_SUBMISSION_NOTE_ACTION_START}};
const addSubmissionNoteOk = (note) => { return { type: ADD_SUBMISSION_NOTE_ACTION_OK, note}};
const addSubmissionNoteErr = (error) => { return { type: ADD_SUBMISSION_NOTE_ACTION_ERR, error }};

export function addSubmissionNoteAction(submissionid, note, successCb, errorCb) {

	console.log('addSubmissionNotesAction START', {submissionid, note});

	return async (dispatch) => {

		dispatch(addSubmissionNoteStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/note`;
			console.log('ADDING SUBMISSION NOTE ... ' + endpoint);
				
			const payload = await API.post('AncestryVmpApi', endpoint, {body: { note }});
			dispatch(addSubmissionNoteOk(payload.note));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST add submission note error:', { err: JSON.stringify(error, true, 4) });
			dispatch(addSubmissionNoteErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const DELETE_SUBMISSION_ACTION_START = 'DELETE_SUBMISSION_ACTION_START';
export const DELETE_SUBMISSION_ACTION_OK = 'DELETE_SUBMISSION_ACTION_OK';
export const DELETE_SUBMISSION_ACTION_ERR = 'DELETE_SUBMISSION_ACTION_ERR';

const deleteSubmissionStart = () => { return { type: DELETE_SUBMISSION_ACTION_START}};
const deleteSubmissionOk = (submissionid) => { return { type: DELETE_SUBMISSION_ACTION_OK, submissionid}};
const deleteSubmissionErr = (error) => { return { type: DELETE_SUBMISSION_ACTION_ERR, error }};

export function deleteSubmissionAction(submissionid, successCb, errorCb) {

	console.log('deleteSubmissionsAction START, know what im saying?', {submissionid});

	return async (dispatch) => {

		dispatch(deleteSubmissionStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/delete`;
			console.log('DELETE SUBMISSION ... ' + endpoint);
				
			await API.post('AncestryVmpApi', endpoint);
			dispatch(deleteSubmissionOk(submissionid));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST delete submission error:', { err: JSON.stringify(error, true, 4) });
			dispatch(deleteSubmissionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const ADD_RATING_ACTION_START = 'ADD_RATING_ACTION_START';
export const ADD_RATING_ACTION_OK = 'ADD_RATING_ACTION_OK';
export const ADD_RATING_ACTION_ERR = 'ADD_RATING_ACTION_ERR';

const addRatingStart = () => { return { type: ADD_RATING_ACTION_START}};
const addRatingOk = (submission) => { return { type: ADD_RATING_ACTION_OK, submission}};
const addRatingErr = (error) => { return { type: ADD_RATING_ACTION_ERR, error }};

export function addRatingAction(submissionid, rating, successCb, errorCb) {

	console.log('addRatingsAction START, know what im saying?', {submissionid});

	return async (dispatch) => {

		dispatch(addRatingStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/rate`;
			console.log('ADD SUBMISSION RATING ... ' + endpoint);
				
			const resp = await API.post('AncestryVmpApi', endpoint, { body: {action: 'addRating', rating}});
			dispatch(addRatingOk(resp.submission));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST add rating error:', { err: JSON.stringify(error, true, 4) });
			dispatch(addRatingErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const REMOVE_RATING_ACTION_START = 'REMOVE_RATING_ACTION_START';
export const REMOVE_RATING_ACTION_OK = 'REMOVE_RATING_ACTION_OK';
export const REMOVE_RATING_ACTION_ERR = 'REMOVE_RATING_ACTION_ERR';

const removeRatingStart = () => { return { type: REMOVE_RATING_ACTION_START}};
const removeRatingOk = (submission) => { return { type: REMOVE_RATING_ACTION_OK, submission}};
const removeRatingErr = (error) => { return { type: REMOVE_RATING_ACTION_ERR, error }};

export function removeRatingAction(submissionid, successCb, errorCb) {

	console.log('removeRatingsAction START, know what im saying?', {submissionid});

	return async (dispatch) => {

		dispatch(removeRatingStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/rate`;
			console.log('REMOVE SUBMISSION RATING ... ' + endpoint);
				
			const resp = await API.post('AncestryVmpApi', endpoint, { body: {action: 'removeRating'}});
			dispatch(removeRatingOk(resp.submission));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST remove rating error:', { err: JSON.stringify(error, true, 4) });
			dispatch(removeRatingErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const SAVE_TAGS_ACTION_START = 'SAVE_TAGS_ACTION_START';
export const SAVE_TAGS_ACTION_OK = 'SAVE_TAGS_ACTION_OK';
export const SAVE_TAGS_ACTION_ERR = 'SAVE_TAGS_ACTION_ERR';

const saveTagsStart = () => { return { type: SAVE_TAGS_ACTION_START}};
const saveTagsOk = (submission) => { return { type: SAVE_TAGS_ACTION_OK, submission}};
const saveTagsErr = (error) => { return { type: SAVE_TAGS_ACTION_ERR, error }};

export function saveTagsAction(submissionid, tags, successCb, errorCb) {

	console.log('saveTagssAction START, know what im saying?', {submissionid});

	return async (dispatch) => {

		dispatch(saveTagsStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/tags`;
			console.log('SAVE SUBMISSION TAGS ... ' + endpoint);
				
			const resp = await API.post('AncestryVmpApi', endpoint, { body: {tags}});
			dispatch(saveTagsOk(resp.submission));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST remove rating error:', { err: JSON.stringify(error, true, 4) });
			dispatch(saveTagsErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const SAVE_TRANSCRIPTION_ACTION_START = 'SAVE_TRANSCRIPTION_ACTION_START';
export const SAVE_TRANSCRIPTION_ACTION_OK = 'SAVE_TRANSCRIPTION_ACTION_OK';
export const SAVE_TRANSCRIPTION_ACTION_ERR = 'SAVE_TRANSCRIPTION_ACTION_ERR';

const saveTranscriptionStart = () => { return { type: SAVE_TRANSCRIPTION_ACTION_START}};
const saveTranscriptionOk = (submission) => { return { type: SAVE_TRANSCRIPTION_ACTION_OK, submission}};
const saveTranscriptionErr = (error) => { return { type: SAVE_TRANSCRIPTION_ACTION_ERR, error }};

export function saveTranscriptionAction(submissionid, transcription, successCb, errorCb) {

	console.log('saveTranscriptionsAction START, know what im saying?', {submissionid, transcription});

	return async (dispatch) => {

		dispatch(saveTranscriptionStart());

		try {
			let endpoint = `/api/admin/submission/${submissionid}/transcription`;
			console.log('SAVE SUBMISSION TRANSCRIPTION ... ' + endpoint);
				
			const resp = await API.post('AncestryVmpApi', endpoint, { body: {transcription}});
			dispatch(saveTranscriptionOk(resp.submission));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST remove rating error:', { err: JSON.stringify(error, true, 4) });
			dispatch(saveTranscriptionErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const PARTNER_SUBMISSIONS_ACTION_START = 'PARTNER_SUBMISSIONS_ACTION_START';
export const PARTNER_SUBMISSIONS_ACTION_OK = 'PARTNER_SUBMISSIONS_ACTION_OK';
export const PARTNER_SUBMISSIONS_ACTION_ERR = 'PARTNER_SUBMISSIONS_ACTION_ERR';

const partnerSubmissionsStart = (searchCriteria) => { return { type: PARTNER_SUBMISSIONS_ACTION_START, searchCriteria }};
const partnerSubmissionsOk = (results, total, criteria) => { 
	return { type: PARTNER_SUBMISSIONS_ACTION_OK, results, total, criteria}
};
const partnerSubmissionsErr = (error) => { return { type: PARTNER_SUBMISSIONS_ACTION_ERR, error }};

export function partnerSubmissionsAction(collectionid, searchCriteria, successCb, errorCb) {

	console.log('partnerSubmissionsAction START');

	return async (dispatch) => {

		const decoded = decodeCriteria((searchCriteria === undefined) ? '' : searchCriteria);
		console.log('partnerSubmissionsAction: START', { searchCriteria, decoded });
	
		dispatch(partnerSubmissionsStart(searchCriteria));

		try {
			let endpoint = `/api/admin/partner-submissions/${collectionid}/${searchCriteria}`;
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryVmpApi', endpoint);
			console.log('QUERY Found submissions', { payload });
			dispatch(partnerSubmissionsOk(payload.results, payload.total, payload.criteria));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY submission error:', { error });
			dispatch(partnerSubmissionsErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}




